import React, { useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import emailjs from 'emailjs-com';
import { FaLinkedin, FaGithub, FaTwitter, FaWhatsapp } from 'react-icons/fa';

const Section = styled.section`
  padding: 50px 20px;
  background: linear-gradient(to bottom, #e6e6fa, #f5f5f5);
  color: #333;
  position: relative;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
`;

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  resize: vertical;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

/* Keyframes for animations */
const rollAnimation = keyframes`
  0% { transform: translateX(-100%); }
  50% { transform: translateX(0); }
  100% { transform: translateX(100%); }
`;

const flashAnimation = keyframes`
  0%, 100% { background-color: #dc3545; }
  50% { background-color: #ff4d4d; }
`;

/* Styled Feedback Card */
const FeedbackCard = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 30px;
  color: #fff;
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  z-index: 1000;
  background-color: ${({ success }) => (success ? '#28a745' : '#dc3545')};
  animation: ${({ success }) =>
      success
        ? rollAnimation
        : `${flashAnimation} 0.5s ease-in-out infinite alternate`}
    2s, fade-out 0.5s ease-in-out 4s;

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const Socials = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const SocialLink = styled.a`
  margin: 0 10px;
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

function Contact() {
  const form = useRef();
  const [feedback, setFeedback] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setFeedback({ success: true, message: 'Message sent successfully!' });
        },
        (error) => {
          console.log(error.text);
          setFeedback({ success: false, message: 'An error occurred. Please try again.' });
        }
      );

    e.target.reset();

    // Automatically clear the feedback message after 4 seconds
    setTimeout(() => setFeedback(null), 4000);
  };

  return (
    <Section id="contact">
      <Heading>Contact Me</Heading>
      {feedback && (
        <FeedbackCard success={feedback.success}>
          {feedback.message}
        </FeedbackCard>
      )}
      <Form ref={form} onSubmit={sendEmail}>
        <Input type="text" name="user_name" placeholder="Your Name" required />
        <Input type="email" name="user_email" placeholder="Your Email" required />
        <Textarea name="message" rows="5" placeholder="Your Message" required />
        <Button type="submit">Send Message</Button>
      </Form>
      <Socials>
        <SocialLink href="https://www.linkedin.com/in/njenga-victor" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </SocialLink>
        <SocialLink href="https://github.com/NjengaC" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </SocialLink>
        <SocialLink href="https://twitter.com/1stSparrow_" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </SocialLink>
        <SocialLink
          href="https://wa.me/+254711470120"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp />
        </SocialLink>
      </Socials>
    </Section>
  );
}

export default Contact;

