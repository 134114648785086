import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: linear-gradient(135deg, #555, #333);
  color: #fff;
  text-align: center;
  padding: 20px 10px;
  position: relative;
  margin-top: auto;
`;

const FooterText = styled.p`
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
`;

const SocialList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  gap: 20px;
`;

const SocialLink = styled.a`
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  transition: color 0.3s, transform 0.3s;

  &:hover {
    color: #00ff7f;
    transform: scale(1.2);
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterText>
        © {new Date().getFullYear()} MyPortfolio. All rights reserved.
      </FooterText>
      <SocialList>
        <li>
          <SocialLink href="https://github.com/NjengaC" target="_blank" rel="noopener noreferrer">
            GitHub
          </SocialLink>
        </li>
        <li>
          <SocialLink href="https://linkedin.com/in/njenga-victor" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </SocialLink>
        </li>
      </SocialList>
    </FooterContainer>
  );
}

export default Footer;
