import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 50px 20px;
  background: linear-gradient(to bottom, #f0f8ff, #e6e6fa);
  color: #333;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const ProjectsRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
`;

const ProjectCard = styled.div`
  background: linear-gradient(135deg, #ffffff, #f7f7f7);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s, box-shadow 0.4s, background-color 0.4s;
  width: calc(100% / 5 - 20px);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: linear-gradient(135deg, #f8f8f8, #eaeaea);
  }

  @media (max-width: 1024px) {
    width: calc(100% / 3 - 20px);
  }

  @media (max-width: 768px) {
    width: calc(100% / 2 - 20px);
  }

  @media (max-width: 480px) {
    width: calc(100% - 20px);
  }
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #666;
`;

const TechStack = styled.div`
  font-size: 0.9rem;
  color: #999;
`;

const Button = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 15px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
`;

function Projects() {
  const projects = [
    {
      title: "Suivi (Django and Flask)",
      description:
        "A parcel pickup-request and sending service platform to streamline shipment management and delivery tracking.",
      techStack: "Django, Flask, PostgreSQL, Bootstrap",
      link: "https://github.com/NjengaC/suivi-specialization",
    },
    {
      title: "Mkulima Network",
      description:
        "An e-commerce platform connecting farmers, investors, and buyers, with features for product listing and secure transactions.",
      techStack: "Flask, JavaScript, HTML/CSS",
      link: "https://github.com/NjengaC/MkulimaNet",
    },
    {
      title: "TractorKE",
      description:
        "A service request platform for agricultural equipment, featuring scheduling, service tracking, and pricing estimation.",
      techStack: "Flask, TailwindCSS, JavaScript",
      link: "https://github.com/NjengaC/TractorKE",
    },
    {
      title: "Starlite Aviation Dispatch",
      description:
        "A FastAPI-powered website for tracking aviation school programs, aircraft maintenance records, and student resources.",
      techStack: "FastAPI, React, PostgreSQL",
      link: "https://github.com/NjengaC/starlite-dispatch",
    },
    {
      title: "Personal Portfolio Website",
      description:
        "A responsive React-based portfolio showcasing projects, technical skills, and achievements.",
      techStack: "React, Heroku, TailwindCSS",
      link: "https://www.theactive.tech",
    },
    {
      title: "E-Commerce Platform",
      description:
        "An online shopping platform with a cart, payments, and user authentication.",
      techStack: "React, Node.js, MongoDB",
      link: "https://github.com/NjengaC/TractorKE",
    },
    {
      title: "Lunch App",
      description:
        "A Flutter-based application where users can log in, order lunch, and track orders.",
      techStack: "Flutter, Dart, SQLite",
      link: "https://github.com/NjengaC/PLP-FINAL-PROJECT",
    },
    {
      title: "RUBY Blog API",
      description:
        "A Ruby/Rails backend API for managing blog posts and user comments.",
      techStack: "Ruby, Ruby on Rails, PostgreSQL",
      link: "https://github.com/NjengaC/Ruby_blog",
    },
  ];

  // Split projects into two balanced rows
  const splitProjects = (arr) => {
    const middle = Math.ceil(arr.length / 2);
    return [arr.slice(0, middle), arr.slice(middle)];
  };

  const [row1, row2] = splitProjects(projects);

  return (
    <Section id="projects">
      <Heading>My Projects</Heading>
      <ProjectsRow>
        {row1.map((project, index) => (
          <ProjectCard key={index}>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
            <TechStack>Tech Stack: {project.techStack}</TechStack>
            <Button href={project.link} target="_blank" rel="noopener noreferrer">
              View Project
            </Button>
          </ProjectCard>
        ))}
      </ProjectsRow>
      <ProjectsRow>
        {row2.map((project, index) => (
          <ProjectCard key={index}>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
            <TechStack>Tech Stack: {project.techStack}</TechStack>
            <Button href={project.link} target="_blank" rel="noopener noreferrer">
              View Project
            </Button>
          </ProjectCard>
        ))}
      </ProjectsRow>
    </Section>
  );
}

export default Projects;

