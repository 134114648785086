import React, { useState } from "react";
import styled from "styled-components";
import { Fade } from "react-reveal";

const Section = styled.section`
  padding: 50px 20px;
  background: linear-gradient(to bottom, #e6e6fa, #f5f5f5);
  color: #333;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Bio = styled.div`
  max-width: 800px;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #007bff;
`;

const Timeline = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TimelineItem = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
  }
`;

const ItemHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #007bff;
`;

const ItemText = styled.p`
  font-size: 1rem;
  color: #666;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
`;

const Button = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: ${({ variant }) =>
    variant === "download" ? "#007bff" : "#28a745"};
  color: #fff;
  border-radius: 5px;
  font-size: 1rem;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${({ variant }) =>
      variant === "download" ? "#0056b3" : "#218838"};
    transform: scale(1.05);
  }
`;

const CertificatesSection = styled.div`
  margin-top: 40px;
`;

const CertificatesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const CertificateCard = styled.div`
  width: 200px;
  height: 140px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const SmallText = styled.span`
  font-size: 0.75rem;
  color: #888;
  margin-top: 5px;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  padding: 20px;
  text-align: center;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CloseButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

function About() {
  const timeline = [
  {
    title: "Curiosity Sparks My Journey",
    description:
      "In 2019, out of pure curiosity, I began exploring the world of programming, starting with languages like C and Python, and diving into small projects to quench my growing interest in technology.",
    year: "2019",
  },
  {
    title: "FreeCodeCamp: The First Step",
    description:
      "In 2021, I joined FreeCodeCamp to build a solid foundation in web development, gaining hands-on experience with HTML, CSS, and JavaScript.",
    year: "2021",
  },
  {
    title: "Expanding Horizons with Codecademy",
    description:
      "In 2022, I enrolled in Codecademy, where I mastered Ruby, expanded my JavaScript skills, and started creating more advanced web applications.",
    year: "2022",
  },
  {
    title: "Comprehensive Training at ALX Africa",
    description:
      "In 2023, I joined ALX Africa's rigorous software engineering program, where I honed my skills in C, Python and its web frameworks, and gained real-world experience through challenging projects Among others",
    year: "2023",
    organization: "ALX Africa",
  },
  {
    title: "Spices Of PLP",
    description:
    "In 2024 I enrolled a 16-week software engineering program at PLP academy, where I specialised in web technologies, Python, Dart and flutter, and gained real-world enterpreneual and personal development skills.",
    year: "2024",
    organization: "Power Learn Projects Academy",
    },
    {
    title: "Backend Track Intern",
    description:
      "Contributed to backend development for e-commerce platforms, mastered scalable systems, and optimized database performance as part of an HNG internship.",
    year: "2024",
    organization: "HNG Internship Program",
  },
  {
    title: "Peer Reviewer and Mentor",
    description:
      "Reviewed backend specialization projects, offering constructive feedback to peers while deepening my expertise in software engineering.",
    year: "2024",
    organization: "ALX Africa",
  },
  {
    title: "Academic Achievement",
    description:
      "Graduated with a Second Upper in Military and Security Studies, developing analytical and strategic thinking skills that complement my technical expertise.",
    year: "2020",
    organization: "Kenyatta University",
  },
];

  const certificates = [
    { title: "ALX Software Engineering", src: "/certificates/alx-software-engineering.jpg", type: "image" },
    { title: "PLP Software Engineering", src: "/certificates/plp-cert.pdf", type: "pdf" },
    { title: "AI Career Essentials", src: "/certificates/ai-career.jpg", type: "image" },
    { title: "Virtual Assistant Course", src: "/certificates/virtual-assistant.jpg", type: "image" },
    { title: "KCSE Certificate", restricted: true },
    { title: "Degree Certificate", restricted: true },
    { title: "Letter of Recognition", src: "/certificates/letter.pdf", type: "pdf" },
  ];

  const [selectedCertificate, setSelectedCertificate] = useState(null);

  return (
    <Section id="about">
      <Heading>About Me</Heading>
      <Content>
        <Fade bottom>
          <Bio>
            Hello! I'm <Highlight>Victor Njenga</Highlight>, a versatile and detail-oriented Software Engineer with experience in designing and developing full-stack applications. I specialize in building <Highlight>scalable backend systems</Highlight> and <Highlight>responsive front-end interfaces</Highlight>. My journey has equipped me with proficiency in technologies such as <Highlight>Django, React, and Flutter</Highlight>, alongside a strong grasp of relational and NoSQL databases.
          </Bio>
        </Fade>
        <Fade bottom>
          <ButtonGroup>
            <Button
              href="/Njenga_CV.pdf"
              target="_blank"
              variant="view"
              rel="noopener noreferrer"
            >
              View Resume
            </Button>
            <Button href="/Njenga_CV.pdf" download variant="download">
              Download Resume
            </Button>
          </ButtonGroup>
        </Fade>
        <Timeline>
          {timeline.map((item, index) => (
            <Fade bottom key={index}>
              <TimelineItem>
                <ItemHeading>
                  {item.year} - {item.title}
                </ItemHeading>
                <ItemText>{item.description}</ItemText>
                {item.organization && (
                  <ItemText>
                    <Highlight>Organization:</Highlight> {item.organization}
                  </ItemText>
                )}
              </TimelineItem>
            </Fade>
          ))}
        </Timeline>
        <CertificatesSection>
          <Heading>Certificates</Heading>
          <CertificatesGrid>
            {certificates.map((certificate, index) => (
              <CertificateCard
                key={index}
                onClick={() =>
                  certificate.restricted
                    ? setSelectedCertificate({
                        title: certificate.title,
                        message:
                          "This certificate is private. Please contact me if you need access.",
                      })
                    : setSelectedCertificate({
                        title: certificate.title,
                        src: certificate.src,
                        type: certificate.type,
                      })
                }
              >
                <div>
                  <span style={{ display: "block" }}>{certificate.title}</span>
                  {!certificate.restricted && <SmallText>Click to View</SmallText>}
                </div>
              </CertificateCard>
            ))}
          </CertificatesGrid>
        </CertificatesSection>
        {selectedCertificate && (
          <>
            <Overlay onClick={() => setSelectedCertificate(null)} />
            <Popup>
              <h3>{selectedCertificate.title}</h3>
              {selectedCertificate.message ? (
                <p>{selectedCertificate.message}</p>
              ) : selectedCertificate.type === "pdf" ? (
                <>
                  <iframe
                    src={selectedCertificate.src}
                    width="100%"
                    height="400px"
                    title={selectedCertificate.title}
                    style={{ border: "none" }}
                  />
                  <p>
                    If the PDF does not display,{" "}
                    <a
                      href={selectedCertificate.src}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      click here to view it in a new tab.
                    </a>
                  </p>
                </>
              ) : (
                <img
                  src={selectedCertificate.src}
                  alt={selectedCertificate.title}
                  style={{ width: "100%", borderRadius: "10px" }}
                />
              )}
              <CloseButton onClick={() => setSelectedCertificate(null)}>
                Close
              </CloseButton>
            </Popup>
          </>
        )}
      </Content>
    </Section>
  );
}

export default About;

