import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { Fade } from 'react-reveal';

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 20px;
  background: url('/assets/background.jpg') center/cover no-repeat;
  color: #fff;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px; /* Restrict the width of the sections */
  margin-bottom: 20px;
`;

const OpaquePlate = styled.div`
  background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for elevation */
`;

const LeftContent = styled(ContentWrapper)`
  flex: 1 1 50%;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const RightContent = styled(ContentWrapper)`
  flex: 1 1 50%;
`;

const HeroIcon = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 20px auto;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SubHeading = styled.h2`
  font-size: 1.5rem;
  color: #ffd700; /* Golden color for subheading */
  margin-bottom: 15px;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }
`;

const SkillGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
`;

const SkillItem = styled.div`
  font-size: 1rem;
  margin: 5px 0;
  color: #fff; /* White text for readability */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  display: flex;
  align-items: center;
  gap: 10px;
`;

const Category = styled.div`
  margin-bottom: 20px;
`;

const CategoryTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #ffd700; /* Golden color for titles */
`;

function Home() {
  return (
    <Section id="home">
      <LeftContent>
        <OpaquePlate>
          <Fade bottom>
            <HeroIcon src="/assets/profile.png" alt="Profile" />
          </Fade>
          <Fade bottom>
            <Heading>Welcome to My Portfolio</Heading>
          </Fade>
          <Fade bottom>
            <SubHeading>👨‍💻 Victor Njenga</SubHeading>
          </Fade>
          <Fade bottom>
            <Paragraph>
              🚀 A passionate software engineer with expertise in crafting efficient and beautiful solutions. 
              I specialize in full-stack development and am dedicated to solving complex problems with innovative technology.
            </Paragraph>
          </Fade>
          <Fade bottom>
            <Link to="projects" smooth={true} duration={500}>
              <Button>Explore My Work</Button>
            </Link>
          </Fade>
        </OpaquePlate>
      </LeftContent>

      <RightContent>
        <OpaquePlate>
          <Fade bottom>
            <Category>
              <CategoryTitle>Programming Languages</CategoryTitle>
              <SkillGrid>
                <SkillItem>🐍 Python</SkillItem>
                <SkillItem>💎 Ruby</SkillItem>
                <SkillItem>💻 C</SkillItem>
                <SkillItem>🔷 C#</SkillItem>
                <SkillItem>🎯 Dart</SkillItem>
                <SkillItem>⚡ JavaScript</SkillItem>
                <SkillItem>🌀 TypeScript</SkillItem>
              </SkillGrid>
            </Category>
            <Category>
              <CategoryTitle>Frameworks</CategoryTitle>
              <SkillGrid>
                <SkillItem>🌐 Django</SkillItem>
                <SkillItem>🌀 Flask</SkillItem>
                <SkillItem>🚉 Ruby on Rails</SkillItem>
                <SkillItem>⚙️ FastAPI</SkillItem>
                <SkillItem>📦 Express</SkillItem>
                <SkillItem>⚛️ React</SkillItem>
                <SkillItem>💡 Next.js</SkillItem>
                <SkillItem>🌟 Vue.js</SkillItem>
              </SkillGrid>
            </Category>
            <Category>
              <CategoryTitle>Databases</CategoryTitle>
              <SkillGrid>
                <SkillItem>🗄️ PostgreSQL</SkillItem>
                <SkillItem>🛢️ MySQL</SkillItem>
                <SkillItem>🌱 MongoDB</SkillItem>
                <SkillItem>🔗 Redis</SkillItem>
                <SkillItem>📦 SQLite</SkillItem>
              </SkillGrid>
            </Category>
            <Category>
              <CategoryTitle>DevOps Tools</CategoryTitle>
              <SkillGrid>
                <SkillItem>🐳 Docker</SkillItem>
                <SkillItem>☸️ Kubernetes</SkillItem>
                <SkillItem>🔧 Git</SkillItem>
                <SkillItem>📜 Terraform</SkillItem>
              </SkillGrid>
            </Category>
            <Category>
              <CategoryTitle>Cloud Platforms</CategoryTitle>
              <SkillGrid>
                <SkillItem>☁️ AWS</SkillItem>
                <SkillItem>☁️ Azure</SkillItem>
                <SkillItem>☁️ GCP</SkillItem>
              </SkillGrid>
            </Category>
          </Fade>
        </OpaquePlate>
      </RightContent>
    </Section>
  );
}

export default Home;

