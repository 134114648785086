import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Fade } from 'react-reveal';

// Define the animation for learning tools
const pulse = keyframes`
  0% {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 123, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.4);
  }
`;

const Section = styled.section`
  padding: 50px 20px;
  background: linear-gradient(to bottom, #f5f5f5, #e6e6fa);
  color: #333;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
`;

const SkillsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 800px;
  margin: 0 auto;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CategoryHeading = styled.h3`
  font-size: 1.8rem;
  color: #007bff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
`;

const SkillTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SkillTag = styled.div`
  background: ${({ learning }) => (learning ? 'transparent' : '#007bff')};
  color: ${({ learning }) => (learning ? '#007bff' : '#fff')};
  border: ${({ learning }) => (learning ? '1px solid #007bff' : 'none')};
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  animation: ${({ learning }) => (learning ? pulse : 'none')} 2s infinite;

  &:hover {
    background-color: ${({ learning }) => (learning ? '#007bff' : '#0056b3')};
    color: #fff;
  }
`;

function Skills() {
  const skills = {
    "Programming Languages": [
      { name: "Python", level: 98 },
      { name: "JavaScript", level: 90 },
      { name: "Ruby", level: 70, learning: true }, // Marked as learning
      { name: "C#", level: 60, learning: true }, // Marked as learning
      { name: "Dart", level: 60 },
      { name: "C", level: 60 },
    ],
    Frameworks: [
      { name: "Django", level: 90 },
      { name: "React", level: 85 },
      { name: "Express", level: 85 },
      { name: "Flask", level: 90 },
      { name: "FastAPI", level: 70 },
      { name: "Flutter", level: 70 },
      { name: "Rails", level: 68, learning: true }, // Marked as learning
    ],
    Tools: [
      "Git",
      { name: "GitLab", learning: true }, // Marked as learning
      "PostgreSQL",
      "MySQL",
      "SQLite",
      { name: "MongoDB", learning: true }, // Marked as learning
      { name: "Redis", learning: true }, // Marked as learning
      { name: "Docker", learning: true }, // Marked as learning
      "Nginx",
      "Apache",
      "Vagrant",
      { name: "Terraform", learning: true }, // Marked as learning
      { name: "AWS", learning: true }, // Marked as learning
      { name: "Azure", learning: true }, // Marked as learning
      "Firebase",
      "Heroku",
      "Netlify",
      "Vercel",
      "Webpack",
      { name: "Babel", learning: true }, // Marked as learning
      "ESLint",
      "Postman",
      "Swagger",
      { name: "Figma", learning: true }, // Marked as learning
    ],
  };

  return (
    <Section id="skills">
      <Heading>Skills</Heading>
      <SkillsWrapper>
        {Object.entries(skills).map(([category, skillList], index) => (
          <Fade bottom key={index}>
            <Category>
              <CategoryHeading>{category}</CategoryHeading>
              <SkillTagWrapper>
                {skillList.map((skill, idx) => (
                  <SkillTag
                    key={idx}
                    learning={typeof skill === 'object' && skill.learning}
                  >
                    {typeof skill === 'object' ? skill.name : skill}
                  </SkillTag>
                ))}
              </SkillTagWrapper>
            </Category>
          </Fade>
        ))}
      </SkillsWrapper>
    </Section>
  );
}

export default Skills;

